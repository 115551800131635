import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: window.CONFIG.sentryDsnFrontend,

  integrations: [new Integrations.BrowserTracing()],
  environment: window.CONFIG.env,
  enabled: ['review', 'staging', 'production'].includes(window.CONFIG.env),
  release: process.env.SENTRY_RELEASE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: window.CONFIG.env == 'production' ? 0 : 1.0
})
